.owl-carousel {
  &--item {
    min-height: 280px;
    @include MQ(L) {
      min-height: 680px;
    }
    &-img {
      position: absolute;
    }
    &-text {
      position: absolute;
      right: 15%;
      @include MQ(L) {
        top: 150px;
      }
      & .alt {
        text-transform: uppercase;
      }
      & .bold {
        text-transform: uppercase;
        font-weight: bolder;
      }
    }
    &-link {
      position: absolute;
      background-color: white;
      width: 100%;
      top: 220px;
      @include MQ(L) {
        left: -49%;
        top: 50px;
        transform: rotate(-90deg);
        padding-left: 20px;
        z-index: 2;
      }
      @include MQ (1400px) {
        top: 5px;
      }
      & img {
        width: auto !important;
      }
    }
  }
}