// Settings
// ==================================================
$hamburger-padding-x : 10px !default;
$hamburger-padding-y : 10px !default;
$hamburger-layer-width : 30px !default;
$hamburger-layer-height : 3px !default;
$hamburger-layer-spacing : 3px !default;
$hamburger-layer-color : $primary-color !default;
$hamburger-layer-border-radius : 3px !default;
$hamburger-hover-opacity : 0.7 !default;
$hamburger-hover-transition-duration : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter : opacity(50%) !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter==true {
      filter: $hamburger-hover-filter;
    }

    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }


}

.hamburger--emphatic {
  overflow: hidden;

  .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;

    &::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                  top 0.05s 0.125s linear,
                  left 0.125s 0.175s ease-in;
    }

    &::after {
      top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                  top 0.05s 0.125s linear,
                  right 0.125s 0.175s ease-in;
    }
  }

  &.is-active {
    .hamburger-inner {
      transition-delay: 0s;
      transition-timing-function: ease-out;
      background-color: transparent !important;

      &::before {
        left: $hamburger-layer-width * -2;
        top: $hamburger-layer-width * -2;
        transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * 2, 0) rotate(45deg);
        transition: left 0.125s ease-out,
                    top 0.05s 0.125s linear,
                    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &::after {
        right: $hamburger-layer-width * -2;
        top: $hamburger-layer-width * -2;
        transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * 2, 0) rotate(-45deg);
        transition: right 0.125s ease-out,
                    top 0.05s 0.125s linear,
                    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }
}