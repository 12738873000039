.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 80%;
  width: 80%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  background-color: rgba($white ,0.8);
  z-index: 2;
  margin: 10%;
}

.project-card {
  &--img {
    filter: grayscale(100%);
    &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      opacity: .5;
      transition: 0.5s ease-in-out;
      z-index: 2;
    }
  }
  &:hover &--img{
    filter: grayscale(0);
    &-overlay {
      opacity: 0;
    }
  }
  &:hover .overlay {
	  opacity: 1;
  }
  &:hover &--desc {
	  opacity: 0;
  }
  &--txt {
    font-size: 1.1rem;
    font-weight: 900;
  }

  &--desc{
    position: absolute;
    z-index: 3;
    width: 100%;
    text-align: center;
    @include centerer();
    transition: 0.2s ease-in-out;
    &-title{
      font-size: 1.1rem;
      font-weight: 700;
      &::after {
        content: "";
        display: block;
        width: 100px;
        height: 1px;
        background-color: red;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}