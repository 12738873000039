$primary-font: 'Montserrat', sans-serif;
$font-light: 300;
$font-regular: 400;
$font-bold: 600;
$font-extra-bold: 900;

// Colores
$palette: (
    light-grey: #f1f1f1,
    medium-grey: #424242,
    grey: #2F3640,
    dark-grey: #6b7c8d,
    primary-color: #2e2e2e,
    secondary-color: #90B9FF,
    tertiary-color: #c4c4b3,
    complementary-color: #7ca7ad
);
$light-grey: map-get($palette,light-grey);
$ultra-light-grey: map-get($palette,ultra-light-grey);
$medium-grey: map-get($palette,medium-grey);
$grey: map-get($palette,grey);
$dark-grey: map-get($palette,dark-grey);
$primary-color: map-get($palette,primary-color);
$secondary-color: map-get($palette,secondary-color);
$tertiary-color: map-get($palette,tertiary-color);
$complementary-color: map-get($palette,complementary-color);
$white:white;
$black: black;
//Gradientes
// $gradient-yellow-green: linear-gradient(to right, $page-yellow, $primary-color);
// $gradient-red-yellow: linear-gradient(to right, $red, $page-yellow);