@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap");
/* line 2, src/assets/scss/base/_reset.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

/* line 17, src/assets/scss/base/_reset.scss */
blockquote,
q {
  quotes: none;
}

/* line 22, src/assets/scss/base/_reset.scss */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* line 30, src/assets/scss/base/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 1, src/assets/scss/base/_global.scss */
* {
  box-sizing: border-box;
}

/* line 4, src/assets/scss/base/_global.scss */
html {
  scroll-behavior: smooth;
}

/* line 7, src/assets/scss/base/_global.scss */
body {
  font-size: .9rem !important;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3;
  overflow-x: hidden;
  padding-top: 0 !important;
  background: #fbfbfb;
  color: #2e2e2e;
}

/* line 16, src/assets/scss/base/_global.scss */
hr {
  margin: 0 0 10px 0;
}

/* line 19, src/assets/scss/base/_global.scss */
a {
  color: #2e2e2e;
  transition: .32s ease-in-out all;
  font-weight: 400;
  text-decoration: none;
}

/* line 24, src/assets/scss/base/_global.scss */
a:hover {
  color: #424242;
}

/* line 27, src/assets/scss/base/_global.scss */
a:focus {
  text-decoration: none;
}

/* line 31, src/assets/scss/base/_global.scss */
ul {
  list-style-type: none;
  padding-left: 0;
}

/* line 35, src/assets/scss/base/_global.scss */
strong {
  font-weight: 600;
}

/* line 39, src/assets/scss/base/_global.scss */
label {
  font-weight: 400;
}

/* line 43, src/assets/scss/base/_global.scss */
.content-wrapper {
  min-height: calc(100vh - 260px);
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-light-grey {
  background-color: #f1f1f1 !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-light-grey {
  color: #f1f1f1 !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-medium-grey {
  background-color: #424242 !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-medium-grey {
  color: #424242 !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-grey {
  background-color: #2F3640 !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-grey {
  color: #2F3640 !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-dark-grey {
  background-color: #6b7c8d !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-dark-grey {
  color: #6b7c8d !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-primary-color {
  background-color: #2e2e2e !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-primary-color {
  color: #2e2e2e !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-secondary-color {
  background-color: #90B9FF !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-secondary-color {
  color: #90B9FF !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-tertiary-color {
  background-color: #c4c4b3 !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-tertiary-color {
  color: #c4c4b3 !important;
}

/* line 48, src/assets/scss/base/_global.scss */
.bg-complementary-color {
  background-color: #7ca7ad !important;
}

/* line 51, src/assets/scss/base/_global.scss */
.text-color-complementary-color {
  color: #7ca7ad !important;
}

/* line 55, src/assets/scss/base/_global.scss */
.bg-white {
  background: white;
}

/* line 58, src/assets/scss/base/_global.scss */
.subtitle-line::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 1px;
  margin-right: 5px;
  background-color: #2e2e2e;
}

/* line 22, src/assets/scss/components/_hamburguer.scss */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

/* line 40, src/assets/scss/components/_hamburguer.scss */
.hamburger:hover {
  opacity: 0.7;
}

/* line 51, src/assets/scss/components/_hamburguer.scss */
.hamburger-box {
  width: 30px;
  height: 15px;
  display: inline-block;
  position: relative;
}

/* line 58, src/assets/scss/components/_hamburguer.scss */
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

/* line 63, src/assets/scss/components/_hamburguer.scss */
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: #2e2e2e;
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

/* line 76, src/assets/scss/components/_hamburguer.scss */
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

/* line 82, src/assets/scss/components/_hamburguer.scss */
.hamburger-inner::before {
  top: -6px;
}

/* line 86, src/assets/scss/components/_hamburguer.scss */
.hamburger-inner::after {
  bottom: -6px;
}

/* line 93, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic {
  overflow: hidden;
}

/* line 96, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

/* line 99, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

/* line 106, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic .hamburger-inner::after {
  top: 6px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

/* line 116, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

/* line 121, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -60px;
  top: -60px;
  transform: translate3d(60px, 60px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* line 130, src/assets/scss/components/_hamburguer.scss */
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -60px;
  top: -60px;
  transform: translate3d(-60px, 60px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* line 2, src/assets/scss/components/_footer.scss */
.footer-img img {
  max-width: 200px;
}

/* line 1, src/assets/scss/components/_buttons.scss */
.btn {
  border-radius: 0;
  border: none;
  padding: .5rem 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* line 7, src/assets/scss/components/_buttons.scss */
.btn-primary {
  background: #2e2e2e;
}

/* line 1, src/assets/scss/components/_tabs.scss */
.nav-tabs {
  border: none;
}

/* line 3, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-item {
  width: 20%;
}

/* line 5, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-item:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

/* line 11, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link {
  position: relative;
  padding: 10px 0;
  border-radius: 0;
}

/* line 15, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link.active {
  background: none;
  border: none;
}

/* line 18, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link.active::before {
  content: '';
  display: block;
  height: 2px;
  background: #B2282F;
  position: absolute;
  z-index: 200;
  width: 55%;
  top: 0;
}

/* line 29, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link::after {
  content: '';
  display: block;
  height: 2px;
  background: #d2d6dd;
  width: 90%;
  position: absolute;
  top: 0;
}

/* line 38, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link:hover {
  border: none;
  color: #2e2e2e;
}

/* line 42, src/assets/scss/components/_tabs.scss */
.nav-tabs .nav-link:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

/* line 2, src/assets/scss/components/_slider.scss */
.owl-carousel--item {
  min-height: 280px;
}

@media screen and (min-width: 1024px) {
  /* line 2, src/assets/scss/components/_slider.scss */
  .owl-carousel--item {
    min-height: 680px;
  }
}

/* line 7, src/assets/scss/components/_slider.scss */
.owl-carousel--item-img {
  position: absolute;
}

/* line 10, src/assets/scss/components/_slider.scss */
.owl-carousel--item-text {
  position: absolute;
  right: 15%;
}

@media screen and (min-width: 1024px) {
  /* line 10, src/assets/scss/components/_slider.scss */
  .owl-carousel--item-text {
    top: 150px;
  }
}

/* line 16, src/assets/scss/components/_slider.scss */
.owl-carousel--item-text .alt {
  text-transform: uppercase;
}

/* line 19, src/assets/scss/components/_slider.scss */
.owl-carousel--item-text .bold {
  text-transform: uppercase;
  font-weight: bolder;
}

/* line 24, src/assets/scss/components/_slider.scss */
.owl-carousel--item-link {
  position: absolute;
  background-color: white;
  width: 100%;
  top: 220px;
}

@media screen and (min-width: 1024px) {
  /* line 24, src/assets/scss/components/_slider.scss */
  .owl-carousel--item-link {
    left: -49%;
    top: 50px;
    transform: rotate(-90deg);
    padding-left: 20px;
    z-index: 2;
  }
}

@media screen and (min-width: 1400px) {
  /* line 24, src/assets/scss/components/_slider.scss */
  .owl-carousel--item-link {
    top: 5px;
  }
}

/* line 39, src/assets/scss/components/_slider.scss */
.owl-carousel--item-link img {
  width: auto !important;
}

/* line 1, src/assets/scss/components/_projects.scss */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 80%;
  width: 80%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
  margin: 10%;
}

/* line 15, src/assets/scss/components/_projects.scss */
.project-card--img {
  filter: grayscale(100%);
}

/* line 17, src/assets/scss/components/_projects.scss */
.project-card--img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: .5;
  transition: 0.5s ease-in-out;
  z-index: 2;
}

/* line 28, src/assets/scss/components/_projects.scss */
.project-card:hover .project-card--img {
  filter: grayscale(0);
}

/* line 30, src/assets/scss/components/_projects.scss */
.project-card:hover .project-card--img-overlay {
  opacity: 0;
}

/* line 34, src/assets/scss/components/_projects.scss */
.project-card:hover .overlay {
  opacity: 1;
}

/* line 37, src/assets/scss/components/_projects.scss */
.project-card:hover .project-card--desc {
  opacity: 0;
}

/* line 40, src/assets/scss/components/_projects.scss */
.project-card--txt {
  font-size: 1.1rem;
  font-weight: 900;
}

/* line 45, src/assets/scss/components/_projects.scss */
.project-card--desc {
  position: absolute;
  z-index: 3;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
}

/* line 52, src/assets/scss/components/_projects.scss */
.project-card--desc-title {
  font-size: 1.1rem;
  font-weight: 700;
}

/* line 55, src/assets/scss/components/_projects.scss */
.project-card--desc-title::after {
  content: "";
  display: block;
  width: 100px;
  height: 1px;
  background-color: red;
  margin: 0 auto;
  margin-top: 10px;
}

/* line 20, src/assets/scss/main.scss */
.dot-wrapper {
  background-image: radial-gradient(circle, black 10%, transparent 10%), radial-gradient(circle, black 10%, transparent 10%);
  background-size: 20px 20px;
}

/* line 23, src/assets/scss/main.scss */
.dot-wrapper--title {
  font-weight: 900;
}

@media screen and (min-width: 1024px) {
  /* line 23, src/assets/scss/main.scss */
  .dot-wrapper--title {
    font-size: 6rem;
  }
}

/* line 31, src/assets/scss/main.scss */
.more-projects {
  position: relative;
  margin-left: 300px !important;
  margin-top: -30px;
  z-index: -1;
}

@media screen and (min-width: 1024px) {
  /* line 38, src/assets/scss/main.scss */
  .timeline {
    position: relative;
    max-height: 450px;
    margin-top: -120px;
    z-index: 2;
  }
  /* line 44, src/assets/scss/main.scss */
  .timeline img {
    max-height: 470px;
  }
}

/* line 50, src/assets/scss/main.scss */
.nav-wrapper {
  position: absolute;
  top: 0;
}

@media screen and (min-width: 1024px) {
  /* line 50, src/assets/scss/main.scss */
  .nav-wrapper {
    max-width: 85%;
  }
}

@media screen and (min-width: 1400px) {
  /* line 50, src/assets/scss/main.scss */
  .nav-wrapper {
    max-width: 75%;
  }
}

/* line 59, src/assets/scss/main.scss */
.nav-wrapper-proyectos {
  position: absolute;
  top: 0;
}

/* line 63, src/assets/scss/main.scss */
.nav-wrapper-detail {
  position: absolute;
  top: 0;
}

/* line 66, src/assets/scss/main.scss */
.nav-wrapper-detail img {
  filter: grayscale(100%);
}

@media screen and (min-width: 1024px) {
  /* line 72, src/assets/scss/main.scss */
  .subheader {
    margin-top: -80px;
  }
}

/* line 76, src/assets/scss/main.scss */
.subheader--projects {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #fff 20%, #fff 100%);
}

@media screen and (min-width: 992px) {
  /* line 76, src/assets/scss/main.scss */
  .subheader--projects {
    margin-top: -120px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2%, #fff 2%, #fff 100%);
  }
}

@media screen and (min-width: 1300px) {
  /* line 76, src/assets/scss/main.scss */
  .subheader--projects {
    margin-top: -120px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 8%, #fff 8%, #fff 100%);
  }
}

@media screen and (min-width: 1500px) {
  /* line 76, src/assets/scss/main.scss */
  .subheader--projects {
    margin-top: -120px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 18%, #fff 18%, #fff 100%);
  }
}

/* line 91, src/assets/scss/main.scss */
.subheader--detail {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #fff 20%, #fff 100%);
}

@media screen and (min-width: 992px) {
  /* line 91, src/assets/scss/main.scss */
  .subheader--detail {
    margin-top: -160px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2%, #fff 2%, #fff 100%);
  }
}

@media screen and (min-width: 1300px) {
  /* line 91, src/assets/scss/main.scss */
  .subheader--detail {
    margin-top: -120px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 8%, #fff 8%, #fff 100%);
  }
}

@media screen and (min-width: 1500px) {
  /* line 91, src/assets/scss/main.scss */
  .subheader--detail {
    margin-top: -120px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 18%, #fff 18%, #fff 100%);
  }
}

/* line 108, src/assets/scss/main.scss */
.detail--image img {
  filter: grayscale(100%);
  transition: all .5s ease-in-out;
  cursor: pointer;
}

/* line 112, src/assets/scss/main.scss */
.detail--image img:hover {
  filter: grayscale(0);
}

/* line 117, src/assets/scss/main.scss */
.main-nav {
  z-index: 2;
}

/* line 120, src/assets/scss/main.scss */
.primary-img {
  margin-top: -35px;
}

/* line 124, src/assets/scss/main.scss */
.bg-text {
  min-height: 450px;
  background: url("../img/bg-text.png") no-repeat;
}

/* line 129, src/assets/scss/main.scss */
.gs_reveal {
  opacity: 0;
  visibility: hidden;
  transition: transform 0s;
}
