* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: .9rem !important;
  font-family: $primary-font;
  line-height: 1.3;
  overflow-x: hidden;
  padding-top: 0 !important;
  background: #fbfbfb;
  color: #2e2e2e;
}
hr{
  margin: 0 0 10px 0;
}
a {
  color: $primary-color;
  transition: .32s ease-in-out all;
  font-weight: $font-regular;
  text-decoration: none;
  &:hover {
    color: $medium-grey;
  }
  &:focus {
    text-decoration: none;
  }
}
ul{
  list-style-type: none;
  padding-left: 0;
}
strong {
  font-weight: $font-bold;
}

label {
  font-weight: $font-regular;
}

.content-wrapper {
  min-height: calc(100vh - 260px);
}

@each $name, $color in $palette {
  .bg-#{$name}{
    background-color: $color !important;
  }
  .text-color-#{$name}{
    color: $color !important;
  }
}
.bg-white {
  background: $white;
}
.subtitle-line::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 1px;
  margin-right: 5px;
  background-color: $primary-color;
}