// breakpoints
$breakpoints: (
  S: 480px,
  M: 768px,
  L: 1024px,
  XL: 1400px
);

@mixin MQ($breakpoint){
  @if map-has-key($breakpoints,$breakpoint){
    @media screen and (min-width: map-get($breakpoints,$breakpoint)){
      @content;
    }
  } @else {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  }
}