@charset 'UTF-8';
//@import 'bourbon';
@import 'utils/variables';
@import 'utils/functions';
@import 'utils/media';
@import 'utils/mixins';
@import 'utils/placeholders';

@import 'base/reset';
@import 'base/typography';
@import 'base/global';

@import 'components/hamburguer';
@import 'components/footer';
@import 'components/buttons';
@import 'components/tabs';
@import 'components/slider';
@import 'components/projects';

.dot-wrapper {
  background-image: radial-gradient(circle, $black 10%, transparent 10%), radial-gradient(circle, $black 10%, transparent 10%);
  background-size: 20px 20px;
  &--title {
    font-weight: 900;
    @include MQ(L){
      font-size: 6rem;
    }
  }
}

.more-projects {
  position: relative;
  margin-left: 300px !important;
  margin-top: -30px;
  z-index: -1;
}

.timeline {
  @include MQ(L){
    position: relative;
    max-height: 450px;
    margin-top: -120px;
    z-index: 2;
    & img {
      max-height: 470px;
    }
  }
}

.nav-wrapper {
  position: absolute;
  top: 0;
  @include MQ(L){
    max-width: 85%;
  }
  @include MQ(XL){
    max-width: 75%;
  }
  &-proyectos {
    position: absolute;
    top: 0;
  }
  &-detail {
    position: absolute;
    top: 0;
    img {
      filter: grayscale(100%);
    }
  }
}

.subheader {
  @include MQ(L){
    margin-top: -80px;
  }
  &--projects {
    @include MQ(992px){
      margin-top: -120px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,#fff 2%,#fff 100%);
    }
    @include MQ(1300px){
      margin-top: -120px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,#fff 8%,#fff 100%);
    }
    @include MQ(1500px){
      margin-top: -120px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 18%,#fff 18%,#fff 100%);
    }
    background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,#fff 20%,#fff 100%);
  }
  &--detail {
    @include MQ(992px){
      margin-top: -160px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,#fff 2%,#fff 100%);
    }
    @include MQ(1300px){
      margin-top: -120px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,#fff 8%,#fff 100%);
    }
    @include MQ(1500px){
      margin-top: -120px;
      background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 18%,#fff 18%,#fff 100%);
    }
    background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,#fff 20%,#fff 100%);
  }
}

.detail--image img {
  filter: grayscale(100%);
  transition: all .5s ease-in-out;
  cursor: pointer;
  &:hover {
    filter: grayscale(0);
  }
}

.main-nav {
  z-index: 2;
}
.primary-img {
  margin-top: -35px;
}

.bg-text {
  min-height: 450px;
  background: url('../img/bg-text.png') no-repeat;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  transition: transform 0s;
}