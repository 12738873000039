.nav-tabs {
  border: none;
  & .nav-item{
    width: 20%;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  & .nav-link {
    position: relative;
    padding: 10px 0;
    border-radius: 0;
    &.active {
      background: none;
      border: none;
      &::before {
        content: '';
        display: block;
        height: 2px;
        background: #B2282F;
        position: absolute;
        z-index: 200;
        width: 55%;
        top: 0;
      }
    }
    &::after {
      content: '';
      display: block;
      height: 2px;
      background: #d2d6dd;
      width: 90%;
      position: absolute;
      top: 0;
    }
    &:hover {
      border: none;
      color: $primary-color;
    }
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}